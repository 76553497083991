/*
 * @Author: 94290596@qq.com 94290495@qq.com
 * @Date: 2023-10-23 08:56:39
 * @LastEditors: xly 94290596@qq.com
 * @LastEditTime: 2024-02-21 10:29:30
 * @FilePath: /bulb_tools/utils/mixins.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

export default {
  data() {
    return {};
  },
  methods: {
    getUrlParam(url) {
      if (url.includes("http")) {
        window.open(url, "_blank");
      } else {
        this.$router.push({ path: url });
      }
    },

    getPath(url) {
      this.$router.push({ path: url });
    },
  },
  mounted() {},
  created() {},
};
