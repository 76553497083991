<template>
  <div id="app">
    <topNav />
    <router-view />
    <!-- <keep-alive>
      <router-view />
    </keep-alive> -->
    <bottomNav />
  </div>
</template>


<script>
import topNav from "@/components/topNav.vue";
import bottomNav from "@/components/bottomNav.vue";
export default {
  components: {
    topNav,
    bottomNav,
  },
  data() {
    return {};
  },
  created() {
    return;
    // 禁用右键菜单
    document.addEventListener("contextmenu", function (event) {
      event.preventDefault();
    });

    // 禁用复制快捷键 (Ctrl+C 或 Cmd+C)
    document.addEventListener("keydown", function (event) {
      if (
        (event.ctrlKey && event.key === "c") ||
        (event.metaKey && event.key === "c") ||
        event.key == "F12"
      ) {
        event.preventDefault();
      }
    });
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less">
body {
  margin: 0;
  padding: 0;
  min-width: 1200px;
  // user-select: none; /* 兼容现代浏览器 */
  // -webkit-user-select: none; /* 兼容 Safari */
  // -moz-user-select: none; /* 兼容 Firefox */
  // -ms-user-select: none; /* 兼容 IE */
}

img {
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@font-face {
  font-family: "headFont";
  src: url("../src/assets/headFont.ttf");
}

.max_flex {
  height: 100%;
  width: 1200px;
  display: flex;
  position: relative;
}

.center_flex {
  display: flex;
  flex-direction: column;
  width: 950px;
  position: relative;
}

.el-tooltip__popper.is-dark {
  background: transparent !important;
}

.el-tooltip__popper .popper__arrow {
  border-width: 0px !important;
}

.el-tooltip__popper .popper__arrow::after {
  border-width: 0px !important;
}
</style>
