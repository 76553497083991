<!--
 * @Author: xly 94290596@qq.com
 * @Date: 2024-09-12 13:11:27
 * @LastEditors: xly 94290596@qq.com
 * @LastEditTime: 2024-09-18 14:22:25
 * @FilePath: /appnew/src/App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="bottom" v-show="show">
    <div class="max_flex">
      <div style="width: 100%">
        <div class="b_module_1">
          <img src="../assets/index/icon1.png" alt="" />
          <div>景仰园</div>
          <div class="b_m_line"></div>
        </div>
        <div class="b_module_2">
          <div>
            <div style="display: flex; align-items: center; width: 710px">
              <div class="b_m_2">
                <div>联系电话：</div>
                <div><span>陵园咨询电话：</span>010-60763948/1062</div>
                <div><span>客户服务电话：</span>010-60762455/2466</div>
              </div>
              <div class="b_m_2" style="margin-left: 100px">
                <div style="height: 43px"></div>
                <div><span>陵园监督电话：</span>010-60761934</div>
                <div><span>陵 园 地 址：</span>北京市昌平区十三陵镇景陵沟</div>
              </div>
            </div>
            <div style="display: flex; align-items: center; margin-top: 15px">
              <div class="b_m_2">
                <div>友情链接：</div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    width: 320px;
                  "
                >
                  <div class="m_2_txt" @click="getOuterChain(1)">首都之窗</div>
                  <div class="m_2_txt" @click="getOuterChain(2)">
                    北京市民政局
                  </div>
                  <div class="m_2_txt" @click="getOuterChain(3)">
                    昌平区人民政府
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="b_m_2">
            <div style="text-align: center">企业微信</div>
            <img class="b_m_code" src="../assets/index/code2.png" alt="" />
          </div>
          <div class="b_m_2">
            <div style="text-align: center">云祭扫小程序</div>
            <img class="b_m_code" src="../assets/index/code1.png" alt="" />
          </div>
        </div>
        <div class="b_module_3">
          京公网安备:11011402010888号 京ICP备2024067390号-1 Copyright @
          北京景仰园殡葬服务有限公司
        </div>
      </div>
    </div>
  </div>
</template>
  
  
  
<script>
export default {
  name: "bottomNav",
  components: {},
  data() {
    return {
      show: false,
    };
  },
  created() {
    setTimeout(() => {
      this.show = true;
    }, 1000);
  },
  mounted() {},
  methods: {},
};
</script>
  
  <style lang="less">
.bottom {
  width: 100%;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-image: url("../assets/index/bottom.png");

  .b_module_1 {
    display: flex;
    align-items: center;
    margin-top: 100px;
    font-size: 24px;

    img {
      margin-right: 20px;
    }

    .b_m_line {
      flex: 1;
      height: 1px;
      background: #b06f39;
      margin-left: 20px;
    }
  }

  .b_module_2 {
    font-size: 16px;
    display: flex;
    color: #3a1e0c;
    margin: 20px 20px 0px 20px;
    justify-content: space-between;

    .m_2_cur {
      cursor: pointer;
    }

    .m_2_txt {
      cursor: pointer;
      color: #9e9991;

      &:hover {
        color: #ee8938;
      }
    }

    .b_m_2 {
      line-height: 38px;

      span {
        color: #9e9991;
      }

      .b_m_code {
        width: 140px;
        height: 140px;
      }
    }
  }

  .b_module_3 {
    color: #9e9991;
    text-align: center;
    margin: 20px 0px;
    font-size: 14px;
  }
}
</style>
  