<!--
 * @Author: xly 94290596@qq.com
 * @Date: 2024-09-12 13:11:27
 * @LastEditors: xly 94290596@qq.com
 * @LastEditTime: 2024-09-18 14:22:23
 * @FilePath: /appnew/src/components/head.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->

<template>
  <div>
    <div class="fixed">
      <div class="u_modular">
        <marquee loop>
          <div class="u_m_item">
            <div class="u_right">
              <img src="../assets/index/phone.png" />
              <div class="u_r_txt">
                <span>24小时</span>咨询电话：010-60761062
              </div>
              <div class="u_r_center">免费预约班车</div>
            </div>
          </div>
        </marquee>
      </div>
    </div>

    <div class="head">
      <div class="max_flex" style="align-items: center">
        <img class="h_left" src="../assets/index/headLogo.png" />
        <div class="h_center">
          <div>
            <img class="h_c_img" src="../assets/index/headTitle.png" />
            <div class="h_c_type">
              <div
                class="c_t_tab"
                v-for="(item, index) in headTab"
                :key="index"
                @click="getPath(item.url)"
              >
                <div class="t_t_img">
                  <img
                    :class="[
                      $route.path == item.url ||
                      ($route.path == '/newsDetails' && index == 3)
                        ? 'img_yes'
                        : 'img_no'
                    ]"
                    :src="
                      require(`../assets/index/tab_${
                        $route.path == item.url ? 'yes' : 'no'
                      }/${index + 1}.png`)
                    "
                  />

                  <img
                    class="img_bottom"
                    v-if="$route.path == item.url"
                    src="../assets/index/headTab.png"
                  />
                  <img
                    class="img_bottom"
                    v-if="$route.path == '/newsDetails' && index == 3"
                    src="../assets/index/headTab.png"
                  />
                </div>

                <div
                  class="tab_interval"
                  v-if="headTab.length != index + 1"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <el-tooltip placement="top">
          <div slot="content">
            <div class="h_alert">
              <p>云祭扫小程序</p>
              <img src="../assets/index/code1.png" alt="" />
            </div>
          </div>
          <img class="h_right" src="../assets/index/markers.png" alt="" />
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "topNav",
  data () {
    return {
      headTabIndex: 0,
      headTab: [
        { title: "陵园首页", url: "/" },
        { title: "陵园介绍", url: "/introduce" },
        { title: "认识十三陵", url: "/mingTombs" },
        { title: "新闻资讯", url: "/news" },
        { title: "墓碑展示", url: "/tombstone" },
        // { title: "亲情园地", url: "garden.html" },
        { title: "服务中心", url: "/consult" },
        { title: "联系我们", url: "/contact" },
      ],
    };
  },
  created () { },
  mounted () { },
  methods: {
    getPath (url) {
      this.$router.push({ path: url });
    },
  },
};
</script>

<style scoped lang="less">
.fixed {
  z-index: 999;
  position: fixed;
  top: 0px;
  width: 100%;
  height: 48px;
  background: #fff6ea;
  display: flex;
  justify-content: center;
  border-radius: 0px 0px 0px 0px;

  .u_modular {
    width: 1200px;
    height: 100%;

    marquee {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
    }

    .u_m_item {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .u_left {
      width: 155px;
      height: 24px;
      font-family: "headFont";
      font-weight: 400;
      font-size: 20px;
      color: #3a1e0c;
      line-height: 24px;
      letter-spacing: 2px;
      text-align: center;
      font-style: normal;
      text-transform: none;
      margin-left: 20px;
    }

    .u_right {
      display: flex;
      font-size: 16px;
      align-items: center;

      .u_r_txt {
        font-weight: 500;
        font-size: 20px;
        color: #7a3b1b;

        span {
          color: #ee8938;
        }
      }

      img {
        width: 28px;
        height: 28px;
        margin-right: 10px;
      }

      .u_r_center {
        width: 156px;
        height: 32px;
        margin-left: 17px;
        font-family: PingFang SC, PingFang SC;
        font-size: 20px;
        color: #ffffff;
        line-height: 32px;
        text-align: center;
        background-image: url("../assets/index/headTxtBg.png");
      }
    }
  }
}

.head {
  width: 100%;
  height: 162px;
  display: flex;
  position: relative;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background: url("../assets/index/head.png");
  margin-top: 48px;

  .h_left {
    z-index: 99;
    width: 174px;
    height: 211px;
    display: block;
    margin-top: 50px;
    cursor: pointer;
  }

  .h_right {
    width: 99px;
    height: 143px;
    cursor: pointer;
  }

  .h_center {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .h_c_img {
      width: 196px;
      height: 51px;
      display: none;
    }

    .h_c_type {
      display: flex;
      align-items: center;

      /* margin-top: 20px; */
      .yes_tab {
        font-size: 28px;
        color: #fcd4a3;
      }

      .c_t_tab {
        display: flex;
        align-items: center;

        .t_t_img {
          position: relative;

          .img_yes {
            width: 125px;
            height: 33px;
          }

          .img_no {
            width: 91px;
            height: 25px;
          }
        }

        .img_bottom {
          width: 50px;
          margin-top: 3px;
          left: 50%;
          position: absolute;
          transform: translateX(-50%);
        }

        img {
          cursor: pointer;
          display: block;
        }

        .tab_interval {
          width: 1.5px;
          height: 14px;
          background: #dfbea2;
          margin: 0px 13px;
          transform: rotate(42deg);
        }
      }
    }
  }
}

.h_alert {
  width: 140px;
  height: 200px;
  margin-top: -20px;
  margin-left: -30px;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-repeat: no-repeat;
  background-image: url("../assets/index/eject.png");

  p {
    margin: 15px 0px 8px 0px;
  }

  img {
    width: 95px;
    height: 95px;
  }
}
</style>
