/*
 * @Author: xly 94290596@qq.com
 * @Date: 2024-09-12 13:11:27
 * @LastEditors: xly 94290596@qq.com
 * @LastEditTime: 2024-09-18 10:56:26
 * @FilePath: /appnew/src/router/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "index",
        component: function () {
            return import("../views/index.vue");
        },
    },
    {
        path: "/introduce",
        name: "introduce",
        component: function () {
            return import("../views/introduce.vue");
        },
    },
    {
        path: "/news",
        name: "news",
        component: function () {
            return import("../views/news.vue");
        },
    },
    {
        path: "/newsDetails",
        name: "newsDetails",
        component: function () {
            return import("../views/newsDetails.vue");
        },
    },
    {
        path: "/tombstone",
        name: "tombstone",
        component: function () {
            return import("../views/tombstone.vue");
        },
    },
    {
        path: "/mingTombs",
        name: "mingTombs",
        component: function () {
            return import("../views/mingTombs.vue");
        },
    },
    {
        path: "/consult",
        name: "consult",
        component: function () {
            return import("../views/consult.vue");
        },
    },
    {
        path: "/contact",
        name: "contact",
        component: function () {
            return import("../views/contact.vue");
        },
    },
];

const router = new VueRouter({
    base: process.env.BASE_URL,
    routes,
});

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch((err) => err)
}


// 设置滚动行为
router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);
});

export default router;
