/*
 * @Author: xly 94290596@qq.com
 * @Date: 2024-09-12 13:11:27
 * @LastEditors: xly 94290596@qq.com
 * @LastEditTime: 2024-09-18 14:04:55
 * @FilePath: /appnew/src/main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import axios from "axios";
import "element-ui/lib/theme-chalk/index.css";
import Viewer from "v-viewer";
import "viewerjs/dist/viewer.css";
import mixins from "@/utils/mixins.js";

Vue.mixin(mixins);
Vue.use(Viewer);
Vue.prototype.axios = axios;
Vue.config.productionTip = false;
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
