var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"fixed"},[_c('div',{staticClass:"u_modular"},[_c('marquee',{attrs:{"loop":""}},[_c('div',{staticClass:"u_m_item"},[_c('div',{staticClass:"u_right"},[_c('img',{attrs:{"src":require("../assets/index/phone.png")}}),_c('div',{staticClass:"u_r_txt"},[_c('span',[_vm._v("24小时")]),_vm._v("咨询电话：010-60761062 ")]),_c('div',{staticClass:"u_r_center"},[_vm._v("免费预约班车")])])])])],1)]),_c('div',{staticClass:"head"},[_c('div',{staticClass:"max_flex",staticStyle:{"align-items":"center"}},[_c('img',{staticClass:"h_left",attrs:{"src":require("../assets/index/headLogo.png")}}),_c('div',{staticClass:"h_center"},[_c('div',[_c('img',{staticClass:"h_c_img",attrs:{"src":require("../assets/index/headTitle.png")}}),_c('div',{staticClass:"h_c_type"},_vm._l((_vm.headTab),function(item,index){return _c('div',{key:index,staticClass:"c_t_tab",on:{"click":function($event){return _vm.getPath(item.url)}}},[_c('div',{staticClass:"t_t_img"},[_c('img',{class:[
                    _vm.$route.path == item.url ||
                    (_vm.$route.path == '/newsDetails' && index == 3)
                      ? 'img_yes'
                      : 'img_no'
                  ],attrs:{"src":require(`../assets/index/tab_${
                      _vm.$route.path == item.url ? 'yes' : 'no'
                    }/${index + 1}.png`)}}),(_vm.$route.path == item.url)?_c('img',{staticClass:"img_bottom",attrs:{"src":require("../assets/index/headTab.png")}}):_vm._e(),(_vm.$route.path == '/newsDetails' && index == 3)?_c('img',{staticClass:"img_bottom",attrs:{"src":require("../assets/index/headTab.png")}}):_vm._e()]),(_vm.headTab.length != index + 1)?_c('div',{staticClass:"tab_interval"}):_vm._e()])}),0)])]),_c('el-tooltip',{attrs:{"placement":"top"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"h_alert"},[_c('p',[_vm._v("云祭扫小程序")]),_c('img',{attrs:{"src":require("../assets/index/code1.png"),"alt":""}})])]),_c('img',{staticClass:"h_right",attrs:{"src":require("../assets/index/markers.png"),"alt":""}})])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }